<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 日志管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>断线统计</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input clearable size="mini" v-model="mnInput" placeholder="MN号">
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <div class="block">
          <span class="demonstration">选择日期 </span>
          <el-date-picker
            v-model="date"
            @change="handleDateChange"
            type="date"
            size="mini"
          >
          </el-date-picker>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="monitoryPointName" label="监控点名称">
        </el-table-column>
        <el-table-column prop="mn" label="MN号"> </el-table-column>
        <el-table-column prop="unsentDataCount" label="未发送数据次数">
        </el-table-column>
        <el-table-column prop="autoOffLineCount" label="自动断开次数">
        </el-table-column>
        <el-table-column prop="abnormalOffLineCount" label="未正常断开次数">
        </el-table-column>
        <el-table-column prop="total" label="合计"> </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getBreakStatisticLog } from "@/api/log";

export default {
  name: "OffLineStatistic",
  data() {
    return {
      mnInput: "",
      date: new Date(),
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.mnInput = "";
    },
    handleSearch() {
      console.log(this.mnInput);
      this.getList();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleDateChange(val) {
      this.date = new Date(val).format("yyyy-MM-dd hh:mm:ss");
      this.getList();
    },
    getList() {
      const params = {
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        date: new Date(this.date).getTime(),
        mnInput: this.mnInput,
      };
      getBreakStatisticLog(params).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
